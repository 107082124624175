hr{
  width: 80%;
  background: white;
}

.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1.5vmin);
  color: white;
}

.MenuBar {
  background-color: #282c34;
  width: 80%;
  position: sticky;
  top: 0px;
  z-index: 999;
  padding: 1% 0;
  display: flex;
}

.MenuItems {
  cursor: pointer;
  flex: 1;
}

.MenuItems:hover {
  color: #61dafb;
}

.App-link {
  color: whitesmoke;
  text-decoration: underline;
}

.App-link:hover{
  color: #a147ca;
}

.Banner{
  width: 100%;
}

.parallax {
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallaxText{
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  color: white;
}

.MenuHeader {
  color: #61dafb;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}

.AboutMePic {
  display: flex;
  align-items: center;
  justify-content: center;
}

.AboutMeHeader{
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  padding-top: 3%;
}

.AboutMeDetails {
  padding: 5% 0 5%;
  margin:0 10%; 
}

.Quote {
  color: #61dafb;
  font-family: 'Courier New', Courier, monospace;
}

.Resume{
  background-color: rgb(180, 88, 88);
  width: 100%;
}

.ResumeHeader{
  text-align: center;
  margin: 0 10%;
  color: #61dafb;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}

.ResumeSegment{
  margin: 0 10%;
}

.ResumeSegment .col-3 > p{
  font-family: 'Courier New', Courier, monospace;
  margin: 0 !important;
}

.ResumeTitle{
  font-weight: bold;
}

.ResumeCompany{
  font-size:80%;
}

.ResumeLocation{
  font-size:70%;
}

.ResumeDate{
  font-style: italic;
  font-size: 70%
}

.ResumeSegment .col-9{
  text-align: left;
  justify-content: left;
}

.ContactInfo{
  width: 100%;
  color: #282c34;
  background-color: #61dafb;
  padding-bottom: 5vh;
}

.ContactPic {
  height: 1.5em; 
  width: auto;
  padding: 0 1%;
}

.ContactDetails {
  display: inline-block;
}

#ProfilePic {
  height: auto; 
  width: 100%;
}

#ProfileDetails{
  display: flex;
}

#ProfileDetails .col{
  flex: 1;
  font-size: 90%;
  display: flex;
  align-items: center;
  padding: 0 1.5%;
}

#Author {
  font-size: 70%;
}

@media all and (max-width: 700px) {
  .MenuBar {
    flex-wrap: wrap;
  }
  .MenuBar > .MenuItems {
    flex: 1 1 50%;
  }

  #ProfileDetails{
    flex-wrap: wrap;
  }

  #ProfileDetails .col{
    flex: 100%;
    align-items: center;
    justify-content: center;
    padding: 3% 1.5%;
  }

  #ProfilePic {
    height: 50vh; 
    width: auto;
  }
}